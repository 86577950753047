import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/_directives/sortable.directive';
import { TicketService } from 'src/app/_services/ticket.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { Router } from '@angular/router';
import { Ticket } from 'src/app/_models/Ticket';
import { ToastService } from 'src/app/_services/toastservice';
import { NgbdDatepickerRangePopup } from 'src/app/datepicker-range/datepicker-range-popup';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  providers: []
})

export class TicketComponent implements OnInit {
  @ViewChild(NgbdDatepickerRangePopup, { static: true }) _datepicker;
  data$: Observable<Ticket[]>;  
  oldData: Ticket[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedBrand;
  
  locationSubscription: Subscription;
  submit: boolean;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: TicketService,
    public ls :LocalStorageService,
    public ts :ToastService,
    public router:Router) {

    this.loading$ = service.loading$;
    this.submit = false;
    
  }

  ngOnInit() {
     //this.getData();
     this.getDataRange();
  }

  getData() {    
    this.service.getAllData();    
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
  }
  getDataRange() { 
    debugger
    this.service.getDataRange(this.parseDate(this._datepicker.fromDate), this.parseDate(this._datepicker.toDate));    
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
  }
  parseDate(obj) {
    return obj.year + "-" + obj.month + "-" + obj.day;;
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  View(tickets) {
    this.router.navigate(["admin/ticket/view", tickets]);
  }
  Filter() {
    debugger
    this.getDataRange();
  }
  //Status(obj) {
  //  this.service.status(obj).subscribe((res: any) => {
  //    if(res!=0){
  //      this.ts.showSuccess("Success","status updated successfully.")
  //      this.getData();
  //    }
  //    else
  //      this.ts.showError("Error","Failed to update status.")

  //  }, error => {
  //    this.ts.showError("Error","Failed to update status.")
  //  });
  //}
}
