import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { LayoutComponent } from './layout/layout.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CategoryComponent } from './admin/menu/category/category.component';
import { AddcategoryComponent } from './admin/menu/category/addcategory/addcategory.component';
import { serviceCategoryComponent } from './admin/menu/serviceCategory/serviceCategory.component';
import { AddserviceCategoryComponent } from './admin/menu/serviceCategory/addserviceCategory/addserviceCategory.component';
import { ServiceComponent } from './admin/menu/services/services.component';
import { AddServiceComponent } from './admin/menu/services/addservice/addservice.component';
import { ImageuploadComponent } from './imageupload/imageupload.component';
import { ItemsComponent } from './admin/menu/items/items.component';
import { AdditemsComponent } from './admin/menu/items/additem/additem.component';
import { ModifiersComponent } from './admin/menu/modifiers/modifiers.component';
import { AddmodifierComponent } from './admin/menu/modifiers/addmodifier/addmodifier.component';
import { CustomersComponent } from './admin/customer/customers/customers.component';
import { AddcustomerComponent } from './admin/customer/customers/addcustomers/addcustomer.component';
import { LocationsComponent } from './admin/company/locations/locations.component';
import { AddlocationComponent } from './admin/company/locations/addlocation/addlocation.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddbrandComponent } from './admin/company/brands/addbrand/addbrand.component';
import { ExcelService } from 'src/ExportExcel/excel.service';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ToastrModule } from 'ngx-toastr';
import { BrandComponent } from './admin/company/brands/brands.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SummaryComponent } from './admin/report/summary/summary.component';
import { BannerComponent } from './admin/settings/banner/banner.component';
import { AddbannerComponent } from './admin/settings/banner/addbanner/addbanner.component';
import { SalesdetailComponent } from './admin/report/salesdetail/salesdetail.component';
import { SalesuserwiseComponent } from './admin/report/salesuserwise/salesuserwise.component';
import { SalescustomerwiseComponent } from './admin/report/salescustomerwise/salescustomerwise.component';
import { SalescategorywiseComponent } from './admin/report/salescategorywise/salescategorywise.component';
import { SalesitemwiseComponent } from './admin/report/salesitemwise/salesitemwise.component';
import { OffersComponent } from './admin/settings/offers/offers.component';
import { AddoffersComponent } from './admin/settings/offers/addoffers/addoffers.component';
import { OrdersComponent } from './admin/sales/orders/orders.component';
import { OrderdetailsComponent } from './admin/sales/orderdetails/orderdetails.component';
import { NgbdDatepickerRangePopup } from './datepicker-range/datepicker-range-popup';
import { TicketComponent } from './admin/customer/tickets/ticket.component';
import { TicketdetailsComponent } from './admin/customer/ticketdetails/ticketdetails.component';
import { AppsettingsComponent } from './admin/settings/appsettings/appsettings.component';
import { CouponComponent } from './admin/settings/coupon/coupon.component';
import { AddcouponComponent } from './admin/settings/coupon/add/addcoupon.component';
import { SupplierComponent } from './admin/menu/supplier/supplier.component';
import { AddSupplierComponent } from './admin/menu/supplier/addsupplier/addsupplier.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { EditOrderComponent } from './admin/sales/editorder/editorder.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    DashboardComponent,
    LayoutComponent,
    CounterComponent,
    LoginComponent,
    FetchDataComponent,
    CategoryComponent,
    AddcategoryComponent,
    serviceCategoryComponent,
    AddserviceCategoryComponent,
    ServiceComponent,
    AddServiceComponent,
    ItemsComponent,
    AdditemsComponent,
    ModifiersComponent,
    AddmodifierComponent,
    CustomersComponent,
    AddcustomerComponent,
    BrandComponent,
    AddbrandComponent,
    LocationsComponent,
    AddlocationComponent,
    ImageuploadComponent,
    SummaryComponent,
    NgbdDatepickerRangePopup,
    BannerComponent,
    AddbannerComponent,
    OffersComponent,
    AddoffersComponent,
    SalesdetailComponent,
    SalescategorywiseComponent,
    SalescustomerwiseComponent,
    SalesitemwiseComponent,
    SalesuserwiseComponent,
    OrdersComponent,
    OrderdetailsComponent,
    TicketComponent,
    TicketdetailsComponent,
    AppsettingsComponent,
    CouponComponent,
    AddcouponComponent,
    SupplierComponent,
    AddSupplierComponent,
    EditOrderComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,    
    EditorModule, 
    NgSelectModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgApexchartsModule,
    RouterModule.forRoot([
      { path: '', component: LoginComponent, pathMatch: 'full' },
      {
        path: 'admin', component: LayoutComponent,
        children: [
          { path: 'dashboard', component: DashboardComponent },
          { path: 'category', component: CategoryComponent },
          { path: 'category/add', component: AddcategoryComponent },
          { path: 'category/edit/:id', component: AddcategoryComponent },

          { path: 'supplier', component: SupplierComponent },
          { path: 'supplier/add', component: AddSupplierComponent },
          { path: 'supplier/edit/:id', component: AddSupplierComponent },

          { path: 'serviceCategory', component: serviceCategoryComponent },
          { path: 'serviceCategory/add', component: AddserviceCategoryComponent },
          { path: 'serviceCategory/edit/:id', component: AddserviceCategoryComponent },

          { path: 'service', component: ServiceComponent },
          { path: 'service/add', component: AddServiceComponent },
          { path: 'service/edit/:id', component: AddServiceComponent },

          { path: 'item', component: ItemsComponent },
          { path: 'item/add', component: AdditemsComponent },
          { path: 'item/edit/:id', component: AdditemsComponent },

          { path: 'orders', component: OrdersComponent },
          { path: 'orders/add', component: OrderdetailsComponent },
          { path: 'orders/view/:id', component: OrderdetailsComponent },
          { path: 'orders/edit/:id', component: EditOrderComponent },

          { path: 'customer', component: CustomersComponent },
          { path: 'customer/add', component: AddcustomerComponent },
          { path: 'customer/edit/:id', component: AddcustomerComponent },

          { path: 'report/summary', component: SummaryComponent },
          { path: 'report/salesdetail', component: SalesdetailComponent },
          { path: 'report/salesitemwise', component: SalesitemwiseComponent },
          { path: 'report/salescustomerwise', component: SalescustomerwiseComponent },
          { path: 'report/salescategorywise', component: SalescategorywiseComponent },

          { path: 'ticket', component: TicketComponent },
          { path: 'ticket/view/:id', component: TicketdetailsComponent },

          { path: 'banner', component: BannerComponent },
          { path: 'banner/add', component: AddbannerComponent },
          { path: 'banner/edit/:id', component: AddbannerComponent },

          { path: 'coupon', component: CouponComponent },
          { path: 'coupon/add', component: AddcouponComponent },
          { path: 'coupon/edit/:id', component: AddcouponComponent },

          { path: 'appsettings', component: AppsettingsComponent },
        ]
      }
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgbModule
  ],
  // providers: [ExcelService], 
  providers: [
    [ExcelService], 
    { provide: TINYMCE_SCRIPT_SRC, useValue: '@tinymce/tinymce.min.js' }
  ],
  exports: [NgbdDatepickerRangePopup],
  bootstrap: [AppComponent, NgbdDatepickerRangePopup]

})
export class AppModule { }
