import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { Router } from '@angular/router';
import { Orders } from 'src/app/_models/Orders';
import { ToastService } from 'src/app/_services/toastservice';
import { OrdersService } from 'src/app/_services/orders.service';
import { delay, map } from 'rxjs/operators';
import { Location } from 'src/app/_models/Location';
import { NgbdDatepickerRangePopup } from 'src/app/datepicker-range/datepicker-range-popup';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  providers: []
})

export class OrdersComponent implements OnInit {
  
  @ViewChild(NgbdDatepickerRangePopup, { static: true }) _datepicker;
  data$: Observable<Orders[]>;  
  oldData: Orders[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private selectedCompany;
  Locations: Location[] = [];
  selectedLocations = [];
  locationID = 0;
  
  locationSubscription: Subscription;
  submit: boolean;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @ViewChild('locationDrp') drplocation: any;
  constructor(public service: OrdersService,
    public ls :LocalStorageService,
    public ts :ToastService,
    public router:Router) {
    this.loading$ = service.loading$;
    this.submit = false;     
    this.selectedCompany =this.ls.getSelectedBrand().companyID;
  
  }

  ngOnInit() {
    this.getData();   
 
  }
 
  getData() { 
    this.service.getAllData(this.selectedCompany, this.parseDate(this._datepicker.fromDate), this.parseDate(this._datepicker.toDate));    
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
  }

  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  View(orders) {
        this.router.navigate(["admin/orders/view", orders]);
  }
  parseDate(obj) {
    return obj.year + "-" + obj.month + "-" + obj.day;;
  }
  Filter() {
    this.getData();
  }

  Edit(orders) {
     
    this.router.navigate(["admin/orders/edit", orders]);
  }
  
}
