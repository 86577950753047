import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';
import { OrdersService } from 'src/app/_services/orders.service';
import { CustomerOrders, OrderCheckout,OrderDetails, OrderDetailsService, Orders } from '../../../_models/Orders';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Items } from 'src/app/_models/Items';
import { DatePipe, formatDate } from '@angular/common';
//import { format } from 'date-fns';




@Component({
  selector: 'app-editorder',
  templateUrl: './editorder.component.html',
  styleUrls: ['./editorder.component.css']
})

export class EditOrderComponent implements OnInit {

 

  Servicetype = "";
  Itemtype = "";
   [x: string]: any;
  submitted = false;
  orderForm: FormGroup;
  loading = false;
  loadingCategory = false;
  
  
  ItemList = [];
  Price =[0];
  ServiceList = [];
  odList = [];
  OrderDetailList = [];
  OrderDetailListItem = [];
  
  DeliveryBoys = [];
  selectedModifierIds: string[];
  public order = new Orders();
  public orderDetails = new OrderDetails();
   
  public orderDetailsService = new OrderDetailsService();   
  public orderCheckout = new OrderCheckout();
  public orderCustomerInfo = new CustomerOrders();

  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts: ToastService,
    private orderService: OrdersService,
    public modalService: NgbModal 
     

  ) {
    this.Servicetype = 'Service';
    this.Itemtype = 'Item';
    // this.selectedBrand = this.ls.getSelectedBrand().brandID;
     
    this.loadItems();
    this.loadService();
    this.createForm();
    
  }

  ngOnInit() {
    this.setSelectedorder();
  }

  get f() { return this.orderForm.controls; }

  private createForm() {
    this.orderForm = this.formBuilder.group({
      contactNo: [''],
      customerAddress: [''],
      nearestPlace: [],
      paymentMethodID:[0],
      orderNo:[''],
      customerName: [''],
      timeSlot:[''],
      amountTotal: [],
      tax: [0],
      serviceCharges: [],
      discountAmount: [],
      email:[''],
      grandTotal: [0],
      locationID: [],
      brandID: this.ls.getSelectedBrand().brandID,
      statusID: [],
      orderDate: [''],
      orderType: [],
      orderID: [],
      orderDetails: [],
      orderDetailsService:[],
      city:[''],
      country:[''],
      coment:[''],
      itemID:[0],
      serviceID:[0],
      serviceDate:[''],
      serviceTime:[''],
      itemsTotal:[0.0],
      servicesTotal:[0],
      totalDiscount:[0],
      serviceDiscount:[0],
      itemDiscount:[0],
      //discount:[0],
      totalTax:[0],      
      deliveryCharges:[0],
      //bookingCharges:[0],
      productTax:[0],
      serviceTax:[0],
      
    });
  }
  
  private editForm(obj) {
    debugger    
    const orderDate = new Date(obj.order.orderDate);
    const formattedDate = `${orderDate.getDate()}-${orderDate.getMonth() + 1}-${orderDate.getFullYear()}`;
    const hours = orderDate.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = ('0' + (hours % 12 || 12)).slice(-2);
    const formattedTime = `${formattedHours}:${('0' + orderDate.getMinutes()).slice(-2)} ${ampm}`;
    
    const result = `${formattedDate} ${formattedTime}`;
    
    

    this.OrderDetailListItem = obj.orderDetails.filter(obj => obj.type === 'Item');
    this.OrderDetailList = obj.orderDetails.filter(obj => obj.type === 'Service');      
    this.f.orderDetailsService.setValue(this.OrderDetailList);
    this.f.orderDetails.setValue(this.OrderDetailListItem);             
    this.f.customerName.setValue(obj.customerOrders.customerName);
    this.f.orderDate.setValue(result);  
   
    this.f.contactNo.setValue(obj.customerOrders.contactNo); 
    this.f.nearestPlace.setValue(obj.customerOrders.nearestPlace);
    this.f.email.setValue(obj.customerOrders.email);
    this.f.city.setValue(obj.customerOrders.city);
    this.f.country.setValue(obj.customerOrders.country);
    this.f.paymentMethodID.setValue(obj.customerOrders.paymentMethodID);
    this.f.orderNo.setValue(obj.order.orderNo);
    this.f.serviceDiscount.setValue(obj.order.serviceDiscount);
    this.f.itemDiscount.setValue(obj.order.itemDiscount);
    //this.f.discount.setValue(obj.order.discount);
    this.f.totalTax.setValue(obj.order.totalTax);
    this.f.tax.setValue(obj.order.tax);
    this.f.productTax.setValue(obj.order.productTax);
    this.f.serviceTax.setValue(obj.order.serviceTax);
    this.f.deliveryCharges.setValue(obj.order.deliveryCharges);
    //this.f.bookingCharges.setValue(obj.order.bookingCharges);
    this.f.grandTotal.setValue(obj.order.grandTotal); 
    this.f.totalDiscount.setValue(obj.order.totalDiscount); 
    this.f.amountTotal.setValue(obj.order.amountTotal);   
    this.f.servicesTotal.setValue(obj.order.servicesTotal);   
    this.f.itemsTotal.setValue(obj.order.itemsTotal);    
    this.f.coment.setValue(obj.order.coment);    
     
    this.f.statusID.setValue(obj.order.statusID);   
  }

  setSelectedorder() {
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingCategory = true;
        this.f.orderID.setValue(sid);
        this.orderService.getById(sid, this.f.brandID.value).subscribe(res => {
          
          //Set Forms
          this.editForm(res);
          this.loadingCategory = false;
        });
      }
    })
  }
  private loadItems() {
    
    this.orderService.loadItems(1).subscribe((res: any) => {
      this.ItemList = res;
    });
  }
 
  private loadService() {
    
    this.orderService.loadService(1).subscribe((res: any) => {
      this.ServiceList = res;
    });
  }

  // onChange(event) {
  //   debugger
  //   let selectElementValue = event.target.value;
  //   let [index, value] = selectElementValue.split(':').map(item => item.trim());

  //   this.orderService.loadItems(value).subscribe((res: any) => {
  //     this.ItemList = res;
  //   });
  // }
  onSelect(newValue) {  
    debugger  
    
    var obj = this.ItemList.find(element => element.itemID == newValue);
    if (newValue != null) {
  
      this.orderDetails.quantity = 1;
      this.orderDetails.price = obj.price * this.orderDetails.quantity;
      this.orderDetails.discountedPrice = obj.discountedPrice;
      
    }    
  }
  Change(qty)
  {
    debugger
    this.orderDetails.price = this.orderDetails.price * qty;
    this.orderDetails.discountedPrice = this.orderDetails.discountedPrice * qty;

  }
  ChangeService(qty)
  {
    debugger
    this.orderDetailsService.price = this.orderDetailsService.price * qty;
    this.orderDetailsService.discountedPrice = this.orderDetailsService.discountedPrice * qty;
  }
ChangeDC(val)
{
  debugger
   
  this.Calculate();
}
  onChange(newValue) {  
    debugger  
    
    var obj = this.ServiceList.find(element => element.serviceID == newValue);
    if (newValue != null) {
  
      this.orderDetailsService.quantity = 1;
      this.orderDetailsService.price = obj.price * this.orderDetailsService.quantity;
      this.orderDetailsService.discountedPrice = obj.discountedPrice;
       
    }    
  }
  onSubmit() {
    debugger
    this.orderForm.markAllAsTouched();
    this.submitted = true;
    if (this.orderForm.invalid) { return; }
    this.loading = true;
    this.f.orderDetailsService.setValue(this.OrderDetailList);
    this.f.orderDetails.setValue(this.OrderDetailListItem);
    if (parseInt(this.f.orderID.value) === 0) {
    } else {
      
      //Update order
      this.orderService.updateOrder(this.orderForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success", "Record updated successfully.")
          this.router.navigate(['/admin/orders']);
        }
      }, error => {
        this.ts.showError("Error", "Failed to update record.")
        this.loading = false;
      });
    }
  }
  RemoveChild(obj) {
    debugger
    const index = this.OrderDetailListItem.indexOf(obj);
    this.OrderDetailListItem.splice(index, 1);
    this.Calculate(); 
  }
  RemoveChild1(obj) {
     debugger
    const index = this.OrderDetailList.indexOf(obj);
    this.OrderDetailList.splice(index, 1);
    this.Calculate(); 
  }
 
  AddChild(val) {
    debugger
 
    var obj = this.ItemList.find(element => element.itemID == val.itemID);
    if (val.itemID != null) {
 
      this.OrderDetailListItem.push({
        name: obj.name,         
        price:  val.price,
        discount:  val.price - val.discountedPrice,
        quantity: val.quantity == null ? 1 : val.quantity,        
        itemID: obj.itemID,
        comments: val.comments,   
        type : this.Itemtype,             
      });
      this.Calculate();
      this.clear();
      this.orderDetails.quantity = 1;
      this.orderDetails.price = 0;
   
    }
  }
  AddChild1(val) {    
    debugger 
    var obj1 = this.ServiceList.find(ele => ele.serviceID == val.serviceID);
    if (val.serviceID != null) {
 
      this.OrderDetailList.push({
        serviceName: obj1.name,         
        price: val.price,
        quantity: val.quantity == null ? 1 : val.quantity,   
        discount: val.price - val.discountedPrice,     
        serviceID: obj1.serviceID,
        comments: val.comments,
        serviceDate: val.serviceDate,
        serviceTime: val.serviceTime,     
        type:this.Servicetype,   
      });
      this.Calculate(); 
      this.clear1();
      this.orderDetailsService.quantity = 1;
      this.orderDetailsService.price = 0;
    }
  }
  clear() {
    this.orderDetails.quantity = 1;
    this.orderDetails.price = 0;

  }
  clear1() {
    this.orderDetailsService.quantity = 1;
    this.orderDetailsService.price = 0;

  }
  open(content, obj) {
    debugger
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result === 'yes') {
            this.RemoveChild(obj);
            this.Calculate(); 
          }
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }
  openService(content, obj) {
    debugger
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result === 'yes') {
            this.RemoveChild1(obj);
            this.Calculate(); 
          }
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReasonService(reason)}`;
        });
      }
      private getDismissReasonService(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }
      Calculate() {
        debugger
        if (this.OrderDetailListItem.length > 0) {
          var amountTotal = 0;
          var itemsTotal = 0;
          var serviceTotal = 0;
          var totalDiscount =0;
          var itemDiscount =0;
          var serviceDiscount =0;
          var totalTax = parseFloat(this.f.productTax.value)+ parseFloat(this.f.serviceTax.value);
           var productTax= parseFloat(this.f.productTax.value);
           var serviceTax= parseFloat(this.f.serviceTax.value);
          var grandTotal=0;
          this.OrderDetailListItem.forEach(ele => {
            itemsTotal += (ele.price);            
            itemDiscount += (ele.discount);            
          });
          this.OrderDetailList.forEach(ele => {
            serviceTotal += (ele.price);
            serviceDiscount += (ele.discount);
          });
          
          debugger
          amountTotal = itemsTotal + serviceTotal;
          this.f.amountTotal.setValue(amountTotal);            
          this.f.itemsTotal.setValue(itemsTotal); 
           
          this.f.servicesTotal.setValue(serviceTotal); 

          totalDiscount = serviceDiscount + itemDiscount;
          this.f.totalDiscount.setValue(totalDiscount);  
          this.f.itemDiscount.setValue(itemDiscount); 
          this.f.serviceDiscount.setValue(serviceDiscount); 

         
          // var productTaxes =(productTax*itemsTotal)/100;
          // var serviceTaxes=(serviceTax*serviceTotal)/100;
          // var totalTaxes =productTaxes+serviceTaxes;
     
          this.f.productTax.setValue(productTax);  
          this.f.serviceTax.setValue(serviceTax);  
          this.f.tax.setValue(totalTax);  
 
          
            grandTotal = (amountTotal - totalDiscount) + parseFloat(this.f.deliveryCharges.value) + totalTax  ;
            this.f.grandTotal.setValue(grandTotal);  
           
          console.log(grandTotal);

          // this.reactiveForm.get('Cost').setValue(cTotal);
        }
        
      }
      
    }
