import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/_services/serviceIT.service';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';
//import tinymce from 'tinymce';
declare var tinymce: any;

@Component({
  selector: 'app-addservice',
  templateUrl: './addservice.component.html',
  styleUrls: ['./addservice.component.css']
})

export class AddServiceComponent implements OnInit {
 // tinymce: any;   
  submitted = false;
  ServiceForm: FormGroup;
  loading = false;
  loadingService = false;
  Categories = [];
  selectedCategory: any;
  Images = [];
  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts: ToastService,
    private ServiceService: ServiceService

  ) {
    this.createForm();
    this.loadCategory();
  }

  ngOnInit() {
    debugger
    this.setSelectedservice();
    
  }
  
  
  get f() { return this.ServiceForm.controls; }

  private createForm() {
     
    
    this.ServiceForm = this.formBuilder.group({
      name: ['', Validators.required],
      arabicName: [''],
      description: [''],
      termsandcondition: [''],
      image: [''],
      alternateImage: [''],
      cost: [0],
      price: [0, Validators.required],
      discountedPrice: [0],
      sku: [''],
      barcode: [''],
      type: [''],
      displayOrder: [0],
      statusID: [true],
      serviceCategoryID: [0],
      categories: [],
      serviceID: 0,
      imagesSource: [''],
      serviceImages: [],
    });
  }
  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        var fileSize = event.target.files[i].size / 100000;
        if (fileSize > 1) { alert("Filesize exceed 1 MB") }
        else {
          reader.onload = (event: any) => {
            console.log(event.target.result);
            this.Images.push(event.target.result);
            this.ServiceForm.patchValue({
              imagesSource: this.Images
            });
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }
  private editForm(obj) {
    debugger
    
    this.f.serviceID.setValue(obj.serviceID);
    this.f.name.setValue(obj.name);
    this.f.arabicName.setValue(obj.arabicName);  
    this.f.description.setValue(obj.description);
    this.f.termsandcondition.setValue(obj.termsandcondition);
    this.f.image.setValue(obj.image);
    this.f.alternateImage.setValue(obj.alternateImage);
    this.f.cost.setValue(obj.cost);
    this.f.price.setValue(obj.price);
    this.f.discountedPrice.setValue(obj.discountedPrice);
    this.f.sku.setValue(obj.sku);
    this.f.barcode.setValue(obj.barcode);
    this.f.type.setValue(obj.type);
    this.f.displayOrder.setValue(obj.displayOrder);
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);
    this.f.serviceCategoryID.setValue(obj.serviceCategoryID);
    this.imgComp.imageUrl = obj.image;
    // this.loadServiceImages(this.f.itemID.value);
    this.selectedCategory = this.Categories.filter(url => url.serviceCategoryID == obj.serviceCategoryID)[0];

  }

  setSelectedservice() {
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingService = true;
        this.f.serviceID.setValue(sid);
        this.ServiceService.getById(sid).subscribe(res => {
          //Set Forms
          this.editForm(res);
          this.loadingService = false;
        });
      }
    })
  }
//   edtor(){
//  tinymce.init(
//       {
//           selector: "#description",   
                           
//       });
//       tinymce.init(
//         {
//             selector: "#termsandcondition",   
                             
//         });
//   }

  onSubmit() {
debugger
    // const description = this.getContentFromEditor('description');
    // const termsandcondition = this.getContentFromEditor('termsandcondition');

    this.ServiceForm.markAllAsTouched();
    this.submitted = true;
    if (this.ServiceForm.invalid) { return; }
    this.loading = true;
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);

    this.f.description.setValue(this.f.description.value);
    this.f.termsandcondition.setValue(this.f.termsandcondition.value);
    
    // this.f.description.setValue(this.f.description);
    //  this.f.termsandcondition.setValue(this.f.termsandcondition);
    this.f.serviceCategoryID.setValue(this.selectedCategory.serviceCategoryID);
    this.f.image.setValue(this.imgComp.imageUrl);

    if (parseInt(this.f.serviceID.value) === 0) {

      //Insert service
      console.log(JSON.stringify(this.ServiceForm.value));
      this.ServiceService.insert(this.ServiceForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success", "Record added successfully.")
          this.router.navigate(['/admin/service']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error", "Failed to insert record.")
        this.loading = false;
      });

    } else {
      //Update service
      this.ServiceService.update(this.ServiceForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success", "Record updated successfully.")
          this.router.navigate(['/admin/service']);
        }
      }, error => {
        this.ts.showError("Error", "Failed to update record.")
        this.loading = false;
      });
    }
  }
  private loadServiceImages(id) {
    this.ServiceService.loadServiceImages(id).subscribe((res: any) => {
      this.Images = res;
      this.f.imagesSource.setValue(this.Images);
    });
  }
  private loadCategory() {
    this.ServiceService.loadCategories(this.ls.getcompanyID()).subscribe((res: any) => {
   
      this.Categories = res;
    });
  }
 
}
