
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AddcategoryComponent } from './addcategory/addcategory.component';
import { NgbdSortableHeader, SortEvent } from 'src/app/_directives/sortable.directive';
import { Category } from 'src/app/_models/Cateogry';
import { CategoryService } from 'src/app/_services/category.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/_services/toastservice';
import { ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from '../../../../ExportExcel/excel.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  providers: []
})
export class CategoryComponent implements OnInit {
  closeResult: string; 
  data$: Observable<Category[]>;
  oldData: Category[];
  total$: Observable<number>;
  loading$: Observable<boolean>;
  private compnayid;
  locationSubscription: Subscription;
  submit: boolean;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: CategoryService,
    public ls: LocalStorageService,
    public ts: ToastService,
    public tss: ToastrService,
    public router: Router,
    public excelService: ExcelService,
    private modalService: NgbModal) {
    this.compnayid = this.ls.getcompanyID();
    this.loading$ = service.loading$;
    this.submit = false;

    
    this.getData();
  }

  ngOnInit() {
  }

  getData() {

    this.service.getAllData(this.compnayid);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  exportAsXLSX(): void {
    debugger
    this.service.ExportList(this.compnayid).subscribe((res: any) => {
      this.excelService.exportAsExcelFile(res, 'Report_Export');
    }, error => {
      this.ts.showError("Error", "Failed to export")
    });
  }
  Edit(category) {
    this.router.navigate(["admin/category/edit", category]);
  }

  Delete(obj) {
    this.service.delete(obj).subscribe((res: any) => {
      if (res != 0) {
        this.ts.showSuccess("Success", "Record deleted successfully.")
        this.getData();
      }
      else {
        this.ts.showError("Error", "Failed to delete record.");
      }
    }, error => {
      this.ts.showError("Error","Failed to delete record.")
    });
  }
  open(content, obj) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      if (result === 'yes') {
        this.Delete(obj);
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
