import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { serviceCategoryService } from 'src/app/_services/serviceCategory.service';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';

@Component({
  selector: 'app-addserviceCategory',
  templateUrl: './addserviceCategory.component.html',
  styleUrls: ['./addserviceCategory.component.css']
})
export class AddserviceCategoryComponent implements OnInit {


  submitted = false;
  serviceCategoryForm: FormGroup;

  loading = false;
  loadingserviceCategory = false;
  ButtonText = "Save"; selectedCityIds
  selectedSubCategoriesIds: string[];
  selectedLocationIds: string[];
  selectedgroupModifierIds: string[];

  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts :ToastService,
    private serviceCategoryService: serviceCategoryService

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedserviceCategory();
  }

  get f() { return this.serviceCategoryForm.controls; }

  private createForm() {
    this.serviceCategoryForm = this.formBuilder.group({
      name: ['', Validators.required],
      arabicName: [''],
      description: [''],
      statusID: [true],
      displayOrder: [0],
      serviceCategoryID: 0,
      image: [''],
      companyID: this.ls.getcompanyID(),
      locationID: null,
      updatedBy: this.ls.getUserInfo().userID
    });
  }

  private editForm(obj) {

    this.f.name.setValue(obj.name);
    this.f.arabicName.setValue(obj.arabicName);
    this.f.displayOrder.setValue(obj.displayOrder);
    this.f.serviceCategoryID.setValue(obj.serviceCategoryID);
    this.f.image.setValue(obj.image);
    this.f.description.setValue(obj.description);
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);
    this.imgComp.imageUrl = obj.image;
  }

  setSelectedserviceCategory() {    
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingserviceCategory = true;
        this.f.serviceCategoryID.setValue(sid);
        this.serviceCategoryService.getById(sid).subscribe(res => {
          //Set Forms
          this.editForm(res);
          this.loadingserviceCategory = false;
        });
      }
    })
  }

  onSubmit() {
    this.serviceCategoryForm.markAllAsTouched();
    this.submitted = true;
    if (this.serviceCategoryForm.invalid) { return; }
    this.loading = true;
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.image.setValue(this.imgComp.imageUrl);

    if (parseInt(this.f.serviceCategoryID.value) === 0) {
      //Insert serviceCategory
      this.serviceCategoryService.insert(this.serviceCategoryForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success","Record added successfully.")
          this.router.navigate(['/admin/serviceCategory']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error","Failed to insert record.")
        this.loading = false;
      });
    } else {
      //Update serviceCategory
      this.serviceCategoryService.update(this.serviceCategoryForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success","Record updated successfully.")
          this.router.navigate(['/admin/serviceCategory']);
        }
      }, error => {
        this.ts.showError("Error","Failed to update record.")
        this.loading = false;
      });
    }
  }
}
