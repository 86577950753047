import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ItemsService } from 'src/app/_services/items.service';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';
import { CategoryService } from 'src/app/_services/category.service';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { Items } from 'src/app/_models/Items';


declare var tinymce: any;
@Component({
  selector: 'app-additem',
  templateUrl: './additem.component.html',
  styleUrls: ['./additem.component.css']
})



export class AdditemsComponent implements OnInit {

  submitted = false;
  itemsForm: FormGroup;
  loading = false;
  loadingItems = false;
  selectedCategory: any;
  selectedSupplier: any;
  Categories = [];
  Suppliers = [];
  Images = [];
  cid = 0; supid = 0;
  isedit = 0;

  editorConfig = {
    base_url: '/tinymce',
    suffix: '.min',
    plugins: 'lists link image table wordcount'
  };

  @ViewChild(ImageuploadComponent, { static: true }) imgComp;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts: ToastService,
    private itemsService: ItemsService,
     

  ) {
    
    this.createForm();
    this.loadCategory(this.cid, this.isedit);
    this.loadSupplier(this.supid,this.isedit); 
  } 
 

  ngOnInit() {
    this.loadCategory(this.cid, this.isedit);
    //this.loadSupplier(); 
    this.setSelecteditem();
    
    //this.edtor();
   
  }
  getContentFromEditor(editorId: string): string {
    return tinymce.get(editorId).getContent();
  }

  get f() { return this.itemsForm.controls; }

  private createForm() {
    
    
    this.itemsForm = this.formBuilder.group({
      name: ['', Validators.required],
      arabicName: [''],
      description: [''],
      image: [''],
      alternateImage: [''],
      cost: [0],
      price: [0, Validators.required],
      discountedPrice: [0],
      sku: [''],
      barcode: [''],
      type: [''],
      displayOrder: [0],
      statusID: [true],
      isTrending: [false],
      isOffer: [false],
      isBestSeller: [false],
      categoryID: [0],
      categories: [],
      suppliers: [],
      itemID: 0,
      imagesSource: [''],
      itemImages: [],
      file: [''],
      id: [0]
    });
  }
  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        var fileSize = event.target.files[i].size / 1024;
        if (fileSize > 150) { alert("File size exceeds 150KB") }
        else {
          reader.onload = (event: any) => {
            console.log(event.target.result);
            this.Images.push(event.target.result);
            this.itemsForm.patchValue({
              imagesSource: this.Images
            });
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }
  private editForm(obj) {
    debugger;   
    //var desc = tinymce.activeEditor.setContent(obj.description);
    this.f.itemID.setValue(obj.itemID);
    this.f.name.setValue(obj.name);
    this.f.arabicName.setValue(obj.arabicName);
    this.f.description.setValue(obj.description);    
     
    //this.editorContent = obj.description;
    //this.f.imageSource.setValue(obj.imageSource);
    //this.f.itemImages.setValue(obj.itemImages);
    this.f.image.setValue(obj.image);
    this.f.alternateImage.setValue(obj.image);
    this.f.cost.setValue(obj.cost);
    this.f.price.setValue(obj.price);
    this.f.discountedPrice.setValue(obj.discountedPrice);
    this.f.sku.setValue(obj.sku);
    this.f.barcode.setValue(obj.barcode);
    this.f.type.setValue(obj.type);
    this.f.displayOrder.setValue(obj.displayOrder);
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);
    this.f.isTrending.setValue(obj.isTrending === 1 ? true : false);
    this.f.isOffer.setValue(obj.isOffer === 1 ? true : false);
    this.f.isBestSeller.setValue(obj.isBestSeller === 1 ? true : false);
    this.loadItemImages(this.f.itemID.value);
    this.loadItemImages(this.f.itemID.value);
  }

  setSelecteditem() {
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      debugger
      if (sid) {
        this.loadingItems = true;
        this.f.itemID.setValue(sid);
        this.itemsService.getById(sid).subscribe(res => {
          //Set Forms
          debugger
          this.editForm(res);          
          this.loadingItems = false;
        });
      }
      else{
        this.loadCategory(0, 0);
        this.loadSupplier(0, 0);
      }
    })
  }
  // edtor(){
  //   tinymce.init(
  //        {
  //            selector: "#description",   
                              
  //        });
         
  //    }
   
  onSubmit() {
    this.itemsForm.markAllAsTouched();
    this.submitted = true;
    if (this.itemsForm.invalid) { return; }
    this.loading = true;
    this.f.categoryID.setValue(this.selectedCategory.categoryID);
    this.f.id.setValue(this.selectedSupplier.id);
    this.f.description.setValue(this.f.description.value);    
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    this.f.isTrending.setValue(this.f.isTrending.value === true ? 1 : 2);
    this.f.isOffer.setValue(this.f.isOffer.value === true ? 1 : 2);
    this.f.isBestSeller.setValue(this.f.isBestSeller.value === true ? 1 : 2);  

    if (parseInt(this.f.itemID.value) === 0) {

      //Insert item
      console.log(JSON.stringify(this.itemsForm.value));
      this.itemsService.insert(this.itemsForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success", "Record added successfully.")
          this.router.navigate(['/admin/item']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error", "Failed to insert record.")
        this.loading = false;
      });

    } else {
      //Update item
      this.itemsService.update(this.itemsForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success", "Record updated successfully.")
          this.router.navigate(['/admin/item']);
        }
      }, error => {
        this.ts.showError("Error", "Failed to update record.")
        this.loading = false;
      });
    }
  }

  private loadCategory(cid, isedit) {
    this.itemsService.loadCategories(this.ls.getcompanyID()).subscribe((res: any) => {
      this.Categories = res;
      debugger
      
      if(isedit==1){        
    this.f.categoryID.setValue(cid);
        this.selectedCategory = this.Categories.filter(url => url.categoryID == cid)[0];    
      }
    });
  }

  private loadSupplier(supid, isedit) {
    this.itemsService.loadSuppliers(this.ls.getcompanyID()).subscribe((res: any) => {
      this.Suppliers = res;
      debugger
      if(isedit==1){        
        this.f.id.setValue(supid);
            this.selectedSupplier = this.Suppliers.filter(url => url.id == supid)[0];        
          }
    });
  }

  private loadItemImages(id) {
    debugger
    this.itemsService.loadItemImages(id).subscribe((res: any) => {
      this.Images = res;
      this.f.imagesSource.setValue(this.Images);
    });
  }

  removeImage(obj) {
    const index = this.Images.indexOf(obj);
    this.Images.splice(index, 1);
    this.f.imagesSource.setValue(this.Images);
  }
}

