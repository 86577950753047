import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { CouponService } from 'src/app/_services/coupon.service';
import { ToastService } from 'src/app/_services/toastservice';

@Component({
  selector: 'app-addcoupon',
  templateUrl: './addcoupon.component.html',
  styleUrls: ['./addcoupon.component.css']
})
export class AddcouponComponent implements OnInit {

  submitted = false;
  couponForm: FormGroup;
  loading = false;
  loadingCoupon = false;
  ButtonText = "Save"; 

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts: ToastService,
    private couponService: CouponService
  )
  {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedCoupon();
  }

  get f() { return this.couponForm.controls; }

  private createForm() {
    this.couponForm = this.formBuilder.group({
      couponID: 0,
      name: ['', Validators.required],
      type: ['', Validators.required],
      amount: [0, Validators.required],
      couponCode: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      statusID: [true],
    });
  }

  private editForm(obj) {
    this.f.name.setValue(obj.name);
    this.f.type.setValue(obj.type);
    this.f.amount.setValue(obj.amount);
    this.f.couponCode.setValue(obj.couponCode);
    this.f.startDate.setValue(obj.startDate);
    this.f.endDate.setValue(obj.endDate);
    this.f.couponID.setValue(obj.couponID);
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);
  }

  setSelectedCoupon() {
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingCoupon = true;
        this.f.couponID.setValue(sid);
        this.couponService.getById(sid).subscribe(res => {
          //Set Forms
          this.editForm(res);
          this.loadingCoupon = false;
        });
      }
    })
  }

  onSubmit() {
    debugger
    this.couponForm.markAllAsTouched();
    this.submitted = true;
    if (this.couponForm.invalid) { return; }
    this.loading = true;
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);

    if (parseInt(this.f.couponID.value) === 0) {
      //Insert coupon
      console.log(JSON.stringify(this.couponForm.value));
      this.couponService.insert(this.couponForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success","Record added successfully.")
          this.router.navigate(['/admin/coupon']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error","Failed to insert record.")
        this.loading = false;
      });
    } else {
      //Update coupon
      this.couponService.update(this.couponForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success","Record updated successfully.")
          this.router.navigate(['/admin/coupon']);
        }
      }, error => {
        this.ts.showError("Error","Failed to update record.")
        this.loading = false;
      });
    }
  }
}
