import { Component, OnInit, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/_services/toastservice';
import { ReportService } from 'src/app/_services/report.service';
import { SalescustomerwiseReport } from 'src/app/_models/Report';
import { NgbdDatepickerRangePopup } from 'src/app/datepicker-range/datepicker-range-popup';
import { delay, map } from 'rxjs/operators';
import { Location } from 'src/app/_models/Location';
@Component({
  selector: 'app-salescustomerwise',
  templateUrl: './salescustomerwise.component.html',
  providers: []
})

export class SalescustomerwiseComponent implements OnInit {
  data$: Observable<SalescustomerwiseReport[]>;
  CustomerList = [];
  selectedCustomerIds = [];

  @ViewChild(NgbdDatepickerRangePopup, { static: true }) _datepicker;
  private selectedCustomer;
  submit: boolean;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(public service: ReportService,
    public ls: LocalStorageService,
    public ts: ToastService,
    public router: Router,
  ) {
    this.loadCustomer();
  }

  ngOnInit() {
   
  }

  getData() {
    debugger
    this.service.SalesCustomerwiseRpt(this.selectedCustomerIds, this.parseDate(this._datepicker.fromDate), this.parseDate(this._datepicker.toDate))
      .subscribe((res: any) => {
        if (res != null) {
          this.data$ = res;
        }
        else
          this.ts.showError("Error", "Something went wrong");

      }, error => {
        this.ts.showError("Error", "Failed to delete record.")
      });
  }

  parseDate(obj) {
    return obj.year + "-" + obj.month + "-" + obj.day;;
  }
   
  private loadCustomer() {
    debugger
    this.service.loadCustomer().subscribe((res: any) => {
      this.CustomerList = res;
    });
  }
}
