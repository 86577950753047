import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/_services/toastservice';
import { AppsettingService } from 'src/app/_services/appsetting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';

@Component({
  selector: 'app-appsettings',
  templateUrl: './appsettings.component.html',
  styleUrls: ['./appsettings.component.css']
})
export class AppsettingsComponent implements OnInit {
  submitted = false;
  settingForm: FormGroup;
  loadingCategory = false;
  loading = false;
  constructor(
    public ts: ToastService,
    private appsettingService: AppsettingService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ls: LocalStorageService
  ) {
    this.createForm();
    companyID: 1;
    this.setSelectedCategory();
  }

  ngOnInit(): void {
  }

  get f() { return this.settingForm.controls; }

  private createForm() {

    this.settingForm = this.formBuilder.group({
      deliveryCharges: [],
      serviceCharges: [],
      discountPercentage: [],
      taxPercentage: [],
      minimumOrderAmount: [],
      serviceBookingCharges: [],
      consultancyCharges: [],
      cardOnDelivery: [],
      currency: [''],
      cod: [],
      payPal: [''],
      benefitPay: [],
      statusID: [true],
      companyID: [1]
    });
  }

  private editForm(obj) {
    this.f.deliveryCharges.setValue(obj.deliveryCharges);
    this.f.serviceCharges.setValue(obj.serviceCharges);
    this.f.discountPercentage.setValue(obj.discountPercentage);
    this.f.taxPercentage.setValue(obj.taxPercentage);
    this.f.minimumOrderAmount.setValue(obj.minimumOrderAmount);
    this.f.serviceBookingCharges.setValue(obj.serviceBookingCharges);
    this.f.consultancyCharges.setValue(obj.consultancyCharges);
    this.f.currency.setValue(obj.currency);
    this.f.cod.setValue(obj.cod);
    this.f.payPal.setValue(obj.payPal);
    this.f.cardOnDelivery.setValue(obj.cardOnDelivery);
    this.f.benefitPay.setValue(obj.benefitPay);
    this.f.statusID.setValue(obj.statusID == 1 ? true : false);
    this.f.companyID.setValue(obj.companyID);
    //this.f.settingID.setValue(obj.settingID);
  }

  setSelectedCategory() {
    this.loadingCategory = true;
    this.appsettingService.getById(this.f.companyID.value).subscribe(res => {
      //Set Forms
      this.editForm(res);
      this.loadingCategory = false;
    });
  }
  onSubmit() {
    debugger
    this.settingForm.markAllAsTouched();
    this.submitted = true;
    if (this.settingForm.invalid) { return; }
    this.loading = true;
    this.f.statusID.setValue(this.f.statusID.value == true ? 1 : 2);
    debugger;
    if (parseInt(this.f.companyID.value) === 0) {
      //Insert appsetting
      this.appsettingService.insert(this.settingForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success", "Record added successfully.")
          this.router.navigate(['/admin/appsettings']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error", "Failed to insert record.")
        this.loading = false;
      });

    } else {
      //Update appsetting
      this.appsettingService.update(this.settingForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success", "Record updated successfully.")
          this.router.navigate(['/admin/appsettings']);
        }
      }, error => {
        this.ts.showError("Error", "Failed to update record.")
        this.loading = false;
      });
    }
  }
}
