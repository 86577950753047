import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ImageuploadComponent } from 'src/app/imageupload/imageupload.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { ToastService } from 'src/app/_services/toastservice';
import { SupplierService } from 'src/app/_services/supplier.service';

@Component({
  selector: 'app-addsupplier',
  templateUrl: './addSupplier.component.html',
  styleUrls: ['./addSupplier.component.css']
})
export class AddSupplierComponent implements OnInit {


  submitted = false;
  supplierForm: FormGroup;

  loading = false;
  loadingCategory = false;
  ButtonText = "Save"; selectedCityIds
  selectedSubCategoriesIds: string[];
  selectedLocationIds: string[];
  selectedgroupModifierIds: string[];

  @ViewChild(ImageuploadComponent, { static: true }) imgComp;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    public ts :ToastService,
    private supplierService: SupplierService

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.setSelectedCategory();
  }

  get f() { return this.supplierForm.controls; }

  private createForm() {
    this.supplierForm = this.formBuilder.group({
      name: ['', Validators.required],
      address: [''],
      email: [''],
      description: [''],
      statusID: [true],
      contactNo:[''],
      id: 0,
     
      companyID: this.ls.getcompanyID(),
     
      lastUpdatedBy: this.ls.getUserInfo().userID
    });
  }

  private editForm(obj) {

    this.f.name.setValue(obj.name);
    this.f.address.setValue(obj.address);
    this.f.email.setValue(obj.email);
    this.f.id.setValue(obj.id);    
    this.f.description.setValue(obj.description);
    this.f.contactNo.setValue(obj.contactNo);
    this.f.statusID.setValue(obj.statusID === 1 ? true : false);
    
  }

  setSelectedCategory() {    
    this.route.paramMap.subscribe(param => {
      const sid = +param.get('id');
      if (sid) {
        this.loadingCategory = true;
        this.f.categoryID.setValue(sid);
        this.supplierService.getById(sid).subscribe(res => {
          //Set Forms
          this.editForm(res);
          this.loadingCategory = false;
        });
      }
    })
  }

  onSubmit() {
    debugger
    this.supplierForm.markAllAsTouched();
    this.submitted = true;
    if (this.supplierForm.invalid) { return; }
    this.loading = true;
    this.f.statusID.setValue(this.f.statusID.value === true ? 1 : 2);
    
    if (parseInt(this.f.id.value) === 0) {
      //Insert supplier
      this.supplierService.insert(this.supplierForm.value).subscribe(data => {
        if (data != 0) {
          this.ts.showSuccess("Success","Record added successfully.")
          this.router.navigate(['/admin/supplier']);
        }
        this.loading = false;
      }, error => {
        this.ts.showError("Error","Failed to insert record.")
        this.loading = false;
      });
    } else {
      //Update category
      this.supplierService.update(this.supplierForm.value).subscribe(data => {
        this.loading = false;
        if (data != 0) {
          this.ts.showSuccess("Success","Record updated successfully.")
          this.router.navigate(['/admin/supplier']);
        }
      }, error => {
        this.ts.showError("Error","Failed to update record.")
        this.loading = false;
      });
    }
  }
}
