import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SummaryReport, SalesdetailReport, SalescategorywiseReport, SalescustomerwiseReport, SalesitemwiseReport } from '../_models/Report';
import { Observable } from 'rxjs';
import { Customers } from '../_models/Customers';
;

@Injectable({
  providedIn: 'root'
})

export class ReportService {

  constructor(private http: HttpClient) {
  }
  loadCustomer() {
    return this.http.get<Customers[]>(`api/customer/all`);
  }

  SalesSummaryRpt(fromDate,toDate) {
    return this.http.get<SummaryReport[]>(`api/report/summary/${fromDate}/${toDate}`);
  }

  SalesDetailRpt(fromDate, toDate) {
    return this.http.get<SalesdetailReport[]>(`api/report/salesdetail/${fromDate}/${toDate}`);
  }

  SalesItemwiseRpt(fromDate,toDate) {
    return this.http.get<SalesitemwiseReport[]>(`api/report/salesitemwise/${fromDate}/${toDate}`);
  }

  SalesCategorywiseRpt(fromDate, toDate) {
    debugger
    return this.http.get<SalescategorywiseReport[]>(`api/report/salescategorywise/${fromDate}/${toDate}`);
  }

  SalesCustomerwiseRpt(customerID, fromDate, toDate) {
    return this.http.get<SalescustomerwiseReport[]>(`api/report/salescustomerwise/${customerID}/${fromDate}/${toDate}`);
  }

  SalesUserwiseRpt(fromDate, toDate) {
    debugger
    return this.http.get<SalesdetailReport[]>(`api/report/salesuserwise/${fromDate}/${toDate}`);
  }
  
  // loadLocations(brandId) {
  //   return this.http.get<Location[]>(`api/location/all/${brandId}`);
  // }

}
