import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardSummary } from '../_models/Dashboard';
import { formatDate } from '@angular/common';



@Injectable({
  providedIn: 'root'
})

export class DashboadService {
  datepipe: any;
  firstDayOfMonth: string;
  constructor(private http: HttpClient) {
  }

  public dashboard: DashboardSummary[];

  GetDashboard(date) {
     //var today = date;
   
    // debugger
    // today = yyyy + '-' + dd + '-' + mm;
    const currentDate = new Date();
    currentDate.setDate(1);
    this.firstDayOfMonth = this.formatDate(currentDate);

    //today = formatDate(new Date(), 'yyyy-mm-dd', 'en');
 
    return this.http.get<any[]>(`api/dashboard/get/${this.firstDayOfMonth}`);
  }
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + date.getDate()).slice(-2);
    const result = `${year}-${month}-${day}`;
    return result;
  }
  GetDashboardRange(fdate,tdate) {
    
    return this.http.get<any[]>(`api/dashboard/range/get/${fdate}/${tdate}`);
  }
}
