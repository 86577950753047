export class Ticket {
  ticketID: number;
  ticketNo: string;
  title: string;
  ticketType: string;
  description: string;
  timeslots: string;
  charges: string;
  customerName: string;
  email: string;
  mobile: string;
  ticketStatus: number;
  statusID: number;
}

export class TicketImages {
  TicketID: number;
  Image: string;
}
