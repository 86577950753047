export class Orders {
  customerID: number;
  orderNo: string;
  coment: string;
  transactionNo: string;
  customerMobile: string;
  address: string;
  customerName: string;
  amountTotal: number;
  tax: number;
  serviceCharges: number;
  grandTotal: number;
  locationID: number;
  brandID: number;
  statusID: number;
  orderDate: string;
  orderType: string
  orderID: number;
  orderPreparedDate: string;
  orderOFDDate: string;
  serviceDiscount:string;
  itemDiscount:string;
  discount:string;
  totalTax:string;
  deliveryCharges:string;
  bookingCharges:string;
  productTax:string;
  serviceTax:string;
}

export class OrderDetails {
  orderDetailID: number;
  orderID: number;
  name: string;
  serviceName: string;
  itemID: number;
  serviceID: number;
  quantity: number;  
  price: number;
  serviceDate: string;
  serviceTime: string;
  cost: number;
  statusID: number;
  type:string;
  problem:string;
  comments:string;
   discountedPrice:number;
   discount:number;
}

export class OrderDetailsService {
  orderDetailID: number;
  orderID: number;
  name: string;
  //serviceName: string;
  //itemID: number;
  serviceID: number;
  quantity: number;
  price: number;
  serviceDate: string;
  serviceTime: string;
  cost: number;
  statusID: number;
  type:string;
  problem:string;
  comments:string;
  discountedPrice:number;

  discount:number;
}

 
export class OrderCheckout {
  orderCheckoutID: number;
  orderID: string;
  paymentMode: string;
  amountPaid: string;
  amountTotal: string;
  tax: number;
  servicesTotal: number;
  servicesCharges: number;
  grandTotal: number;
  checkoutDate: string;
  deliveryCharges:number; 
  productTax: number;
  serviceTax: number;
  ItemDiscount:number;
}
export class CustomerOrders {
  customerOrderID: number;
  customerName: string;
  email: string;
  contactNo: string;
  description: string;
  address: string;
  nearestPlace: string;
  longitude: string;
  latitude: string;
  locationURL: string;
  addressNickName: string;
  addressType: string;
  paymentMethodID: number;
  city: string;
  country: string;
}
export class OrderServiceImg {
  orderServiceImgID: number;
  orderDetailID: number;
  image: string;
  statusID: number;
}
